import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { apiClient } from "../../../utils/apiClient";
import { useAuth0 } from "@auth0/auth0-react";

import { fontSizes, colors } from "../../Tokens";
import { Container } from "../../Container";
import { Row, AutoWidth, CustomWidth } from "../../Grid";
import Header from "../../Header";
import {
  CartDrawer,
  FilterDrawer,
  NoteDrawer,
  HistoryDrawer,
} from "../../Drawer";
import { FilterPanel } from "../../FilterPanel";
import { PortalProductListing } from "../../PortalProduct";
import { Wrapper } from "../../Wrapper";
import { OrderList } from "../../OrderList";
import Skeleton from "react-loading-skeleton";
import { Title } from "../../Title";
import "react-loading-skeleton/dist/skeleton.css";

const ListContainer = styled(Container)`
  padding-top: 157px;

  @media screen and (min-width: 768px) {
    padding-top: 97px;
  }
  @media screen and (max-width: 768px) {
    align: center;
  }
`;

const Note = styled.div`
  font-size: ${fontSizes.xsmall};
  max-width: 80%;
  background-color: white;
  height: auto;
  white-space: pre-wrap;
  color: ${colors.purple};
  margin-top: 6rem;
  border-radius: 4px;
  position: absolute;
  left: 5rem;
  padding: 2rem 2rem;
  width: 80%;
`;

const HistoryContainer = styled.div`
  font-size: ${fontSizes.xsmall};
  max-width: 100%;
  width: 100%;
  background-color: white;
  white-space: pre-wrap;
  color: ${colors.purple};
  margin-top: 7rem;
  position: absolute;
  padding: 2rem 2rem;
  overflow-y: auto;
  top: 1.8rem;
  bottom: 0;
`;

const HistoryHeader = styled.div`
  color: ${colors.purple};
  padding: 2rem 1.5rem;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: ${colors.purple};
    font-size: 2.2rem;
    margin: 0;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 1rem;

    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 24px;
      background: ${colors.pink};
      border-radius: 2px;
    }
  }
`;

const InvoiceHistoryItem = styled.div`
  background: white;
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .product__details {
    cursor: pointer;
  }

  .offer-number {
    color: ${colors.purple};
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  .project-description {
    color: ${colors.purple};
    font-size: 1.4rem;
    margin: 0.8rem 0;
    padding-left: 1rem;
    border-left: 3px solid ${colors.purple};
  }

  .products-list {
    margin-top: 1rem;
    padding-left: 1.2rem;

    li {
      font-size: 1.4rem;
      color: #666;
      margin: 0.5rem 0;
    }
  }

  .delete-button {
    text-align: right;
    padding-right: 1rem;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${colors.purple};
  transition: color 0.2s ease;

  &:hover {
    color: #ff4444;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

function Landing() {
  const [cart, setCart] = useState({
    products: [],
    description: null,
    open: false,
  });
  const [filter, setFilter] = useState({ open: false });
  const [note, setNote] = useState({ open: true });
  const [history, setHistory] = useState({ open: false });
  const [notes, setNotes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [cats, setCats] = useState([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [products] = useState([]);
  const [cartHistory, setCartHistory] = useState([]);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const functions = {
    openCart: () => setCart((prev) => ({ ...prev, open: !prev.open })),
    openNote: () => setNote((prev) => ({ ...prev, open: !prev.open })),
    openHistory: () => setHistory((prev) => ({ ...prev, open: !prev.open })),
    openFilter: () => setFilter((prev) => ({ ...prev, open: !prev.open })),
    remove: (index) => {
      setCart((prev) => {
        const newProducts = [...prev.products];
        newProducts.splice(index, 1);
        return { ...prev, products: newProducts };
      });
    },
    clear: () => setCart((prev) => ({ ...prev, products: [] })),
    update: (index, qty) => {
      setCart((prev) => {
        const newProducts = [...prev.products];
        newProducts[index] = { ...newProducts[index], qty: Number(qty) };
        return { ...prev, products: newProducts };
      });
    },
    add: (row, qty) => {
      setCart((prev) => {
        const existingIndex = prev.products.findIndex((p) => p.id === row.id);
        if (existingIndex >= 0) {
          const newProducts = [...prev.products];
          newProducts[existingIndex] = {
            ...newProducts[existingIndex],
            qty: newProducts[existingIndex].qty + Number(qty),
          };
          return { ...prev, products: newProducts };
        } else {
          return {
            ...prev,
            products: [...prev.products, { ...row, qty: Number(qty) }],
          };
        }
      });
    },
    total: () =>
      cart.products.reduce((sum, row) => {
        const brutoPrijs = parseFloat(
          row.brutoprijs.replace(/[.]/g, "").replace(",", ".")
        );
        return sum + brutoPrijs * row.qty;
      }, 0),
    addBrands: (brand) => {
      setBrands((prev) => {
        if (prev.includes(brand)) {
          return prev.filter((b) => b !== brand);
        } else {
          return [...prev, brand];
        }
      });
    },
    addCats: (cat) => {
      setCats((prev) => {
        if (prev.includes(cat)) {
          return prev.filter((c) => c !== cat);
        } else {
          return [...prev, cat];
        }
      });
    },

    searchCartProducts: async (userId, page, searchTerm, qty) => {
      try {
        const filterRes = await apiClient.portalProducts.list(
          userId,
          searchTerm,
          brands,
          cats,
          page
        );

        console.log("Search results:", filterRes);

        return filterRes.data.length > 0 ? filterRes.data : null;
      } catch (error) {
        console.error("Error searching products:", error);
        return null;
      }
    },
    deleteHistoryItem: async (cartItemId) => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://media-service-group.eu.auth0.com/api/v2/",
          },
        });

        await apiClient.cart.deleteCart(cartItemId, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Update the cart history state after deletion
        setCartHistory((prev) => prev.filter((item) => item.id !== cartItemId));
      } catch (error) {
        console.error("Error deleting cart history item:", error);
      }
    },
    setDescription: (description) => {
      setCart((prevCart) => ({
        ...prevCart,
        description: description,
      }));
    },
  };

  useEffect(() => {
    const getNotes = async () => {
      try {
        const notes = await apiClient.portalProducts.getNotes();
        const noteList = notes.data.map((a) => a.opmerkingen);
        setNotes(noteList.join("\n"));
      } catch (error) {
        console.error("Error fetching notes:", error);
      }
    };
    getNotes();
  }, []);

  // Memoize fetchCartHistory with useCallback
  const fetchCartHistory = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: "https://media-service-group.eu.auth0.com/api/v2/",
        },
      });

      const response = await apiClient.cart.getCart({
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      setCartHistory(response.data);
    } catch (error) {
      console.error("Error fetching cart history:", error);
    }
  }, [getAccessTokenSilently]); // Include getAccessTokenSilently in dependencies

  // Modify the useEffect to depend on isAuthenticated
  useEffect(() => {
    if (isAuthenticated) {
      fetchCartHistory();
    }
  }, [fetchCartHistory, isAuthenticated]); // Add isAuthenticated to dependencies

  // Function to handle the click on the InvoiceHistoryItem and store product data + qty
  const handleInvoiceHistoryClick = async (cartItem) => {
    console.log("Clicked Cart Item Full Data:", cartItem);

    // Clear existing cart and filters
    functions.clear();
    setBrands([]); // Reset brands filter
    setCats([]); // Reset categories filter

    // Set description and continue with existing functionality
    functions.setDescription(cartItem.description || "");

    if (Array.isArray(cartItem.products) && cartItem.products.length > 0) {
      for (const product of cartItem.products) {
        try {
          // Search for the product using its ID
          const searchResults = await functions.searchCartProducts(
            cartItem.userId, // Use cartItem.userId instead of user?.sub
            0, // page
            product.id // search term (product ID)
          );

          console.log("Search results for product:", product.id, searchResults);

          // Check if we got valid results
          if (!searchResults || searchResults.length === 0) {
            console.error("No search results found for product:", product.id);
            continue;
          }

          // Get the first matching product
          const productDetails = searchResults[0];

          if (!productDetails || !productDetails.korting) {
            console.error("Invalid product details:", productDetails);
            continue;
          }

          // Rest of your existing code for adding the product
          const discount =
            1 - parseFloat(productDetails.korting.replace("%", "")) / 100;
          const originalPrice = parseFloat(
            productDetails.brutoprijs.replace(",", ".")
          );
          const discountedPrice = discount * originalPrice;

          let finalPrice;
          if (isAuthenticated) {
            finalPrice = discountedPrice.toLocaleString("nl-NL", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          } else {
            finalPrice = productDetails.brutoprijs.replace(".", ",");
          }

          functions.add(
            {
              id: productDetails.id,
              omschrijving: productDetails.omschrijving,
              merk: productDetails.merk,
              brutoprijs: finalPrice,
              type: productDetails.type,
              korting: productDetails.korting,
            },
            product.qty
          );
        } catch (error) {
          console.error("Error processing product:", product.id, error);
        }
      }

      functions.openHistory(false);
      functions.openCart();
    }
  };

  const handleDelete = async (cartId) => {
    try {
      if (!cartId) {
        console.error("Cart ID is undefined");
        return;
      }

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: "https://media-service-group.eu.auth0.com/api/v2/",
        },
      });

      await apiClient.cart.v1PortalproductCartIdDelete(cartId, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      // Update the cart history state after successful deletion
      setCartHistory((prev) => prev.filter((item) => item.id !== cartId));
    } catch (error) {
      console.error("Error deleting cart:", error);
    }
  };

  return (
    <Wrapper style={{ backgroundColor: "#C1C1C199" }}>
      <Header
        searchTerm={setCurrentSearchTerm}
        products={cart.products}
        functions={functions}
      />

      <ListContainer>
        <Row>
          <AutoWidth>
            <CustomWidth width={"200px"}>
              <FilterPanel
                products={products}
                addCats={functions.addCats}
                addBrands={functions.addBrands}
              />
            </CustomWidth>

            {isAuthenticated ? (
              <PortalProductListing
                brands={brands}
                cats={cats}
                products={products}
                functions={functions}
                searchTerm={currentSearchTerm}
              />
            ) : (
              <Title style={{ paddingTop: "40%" }}>
                Log in om toegang te krijgen tot het Media Service Portal.
              </Title>
            )}
          </AutoWidth>
          <CartDrawer
            className="modal"
            side="right"
            open={cart.open}
            closeDrawer={functions.openCart}
            style={{}}
          >
            <OrderList
              state={cart}
              functions={functions}
              refreshCartHistory={fetchCartHistory}
            />
          </CartDrawer>

          <NoteDrawer
            className="modal"
            side="right"
            open={note.open}
            closeDrawer={functions.openNote}
          >
            <Note>
              <strong>Release Notes: </strong>
              <br />
              <br />
              {notes.length !== 0 ? (
                notes
              ) : (
                <Skeleton count={10} height={10} borderRadius={"7px"} />
              )}
            </Note>
          </NoteDrawer>

          <HistoryDrawer
            className="modal"
            side="right"
            open={history.open}
            closeDrawer={functions.openHistory}
          >
            <HistoryContainer>
              <HistoryHeader>
                <h2>Recente offertes:</h2>
              </HistoryHeader>
              {cartHistory &&
                cartHistory.map((cartItem, index) => (
                  <InvoiceHistoryItem key={index}>
                    <div
                      className="product__details"
                      onClick={() => handleInvoiceHistoryClick(cartItem)}
                    >
                      <div className="offer-number">Offerte #{index + 1}:</div>
                      {cartItem.description ? (
                        <div className="project-description">
                          Projectomschrijving: {cartItem.description}
                        </div>
                      ) : null}
                      <div className="products-list">
                        {Array.isArray(cartItem.products) &&
                        cartItem.products.length > 0 ? (
                          cartItem.products.map((product) => (
                            <li key={product.id}>
                              Artikelnummer: {product.id}, Aantal: {product.qty}
                            </li>
                          ))
                        ) : (
                          <li>No products in the cart.</li>
                        )}
                      </div>
                    </div>
                    <div className="delete-button">
                      <DeleteButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!cartItem.id) {
                            console.error(
                              "Cart item structure not correct:",
                              cartItem
                            );
                          }
                          handleDelete(cartItem.id);
                        }}
                        title="Delete"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M3 6h18" />
                          <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                          <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                          <line x1="10" y1="11" x2="10" y2="17" />
                          <line x1="14" y1="11" x2="14" y2="17" />
                        </svg>
                      </DeleteButton>
                    </div>
                  </InvoiceHistoryItem>
                ))}
            </HistoryContainer>
          </HistoryDrawer>

          <FilterDrawer
            className="modal"
            side="right"
            open={filter.open}
            closeDrawer={functions.openFilter}
          >
            <FilterPanel
              products={products}
              addCats={functions.addCats}
              addBrands={functions.addBrands}
            />
          </FilterDrawer>
        </Row>
      </ListContainer>
    </Wrapper>
  );
}

export default Landing;
